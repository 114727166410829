@import "../../style/tool";
@import "../../style/media-breakpoints";

.container {
  width: 100%;
  padding: 0 2rem;
  position: relative;

  @include for-laptop-up {
    width: convert-unit(rem, 940px);
  }

  .logOut {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}