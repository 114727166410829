@import "../../style/variable";
@import "../../style/media-breakpoints";

.chart {
  height: 10rem;
  width: 10rem;
}

.innerContainer {
  display: flex;
  margin-top: 1.25rem;

  @include for-phone-large-down {
    flex-flow: column;
  }

  @include for-tablet-up {
    align-items: center;
  }

  &_noanswer {
    flex-wrap: wrap;
  }
}

.legend {
  padding-left: 1.5rem;

  @include for-phone-large-down {
    margin-top: 2rem;
  }

  @include for-tablet-up {
    padding-left: 4rem;
  }

  .legendItem {
    position: relative;
    list-style-type: none;
    margin-top: .3rem;

    &::before {
      background: blue;
      border-radius: 100%;
      content: "";
      height: 1rem;
      left: -1.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
    }

    &.legendItem0 {
      &::before {
        background: $color-muted;
      }

      .legendItemValue {
        color: $color-muted;
      }
    }

    @for $i from 0 through 9 {
      $color: rgba($warm-purple, 1 - ($i * .1));

      &.legendItem#{$i + 1} {
        &::before {
          background: $color;
        }

        .legendItemValue {
          color: $color;
        }
      }
    }

    .legendItemValue {
      @include for-tablet-up {
        display: inline-block;
      }

      width: auto;
      min-width: 2.2rem;
    }
  }

  &_noanswer {
    padding-left: 1.575rem;
    flex-basis: 100%;

    @include for-phone-large-down {
      margin-top: 0;
    }
  }
}

.answerLetter {
  font-weight: bold;
  margin-left: 1.5rem;
  width: auto;
}

.answerName {
  margin-left: 1.2rem;
}

.noAnswersMessage {
  font-size: 1.25rem !important;
}

.questionNameHeading {
  font-size: 1.3rem !important;
}