@import '../../../style/media-breakpoints';

@import '../../../style/tool';

.controls {
    display: flex;
    margin-top: 1.5rem;

    @include for-phone-large-down {
        flex-flow: column-reverse;
        align-items: flex-end;
    }

    @include for-tablet-up {
        justify-content: flex-end;
    }

    .controlsButton {
        &:not(:first-child) {
            @include for-tablet-up {
                margin-left: convert-unit(rem, 30px);
            }
        }

        &:not(:last-child) {
            @include for-phone-large-down {
                margin-top: convert-unit(rem, 20px);
            }
        }
    }
}

.items {
    padding: 0px;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.6s ease-out;
}

.collapseHeader {
    padding: 10px;
    background: #f4f4f4;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-out;
    &:hover {
        background: #ddd;
    }
    &:after {
        position: absolute;
        right: 15px;
        top: 17.5px;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.5px 5px 0 5px;
        border-color: #444 transparent transparent transparent;
        transition: 0.3s ease-out;
    }
}

.collapsed {
    .collapseHeader {
        background: #932280;
        color: #fff;
        &:after {
            border-color: #fff transparent transparent transparent;
            transform: rotate(180deg);
        }
    }
    .items {
        padding: 10px;
        height: auto;
        opacity: 1;
    }
}

.language {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 10px;
}
