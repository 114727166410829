@import "../../../../../../style/tool";
@import "../../../../../../style/variable";
@import "../../../../../../style/media-breakpoints";

.description {
  margin-top: 1rem;
  max-width: convert-unit(rem, 300px);

  @include for-laptop-up {
    max-width: convert-unit(rem, 412px);
  }
}

.section {
  margin-top: 3rem;

  &:not(:first-of-type) {
    border-top: 1px solid $warm-purple;
    padding-top: 3rem
  }
}

.sectionHeading {
  font-size: 1.3rem !important;
  font-weight: bold !important;
}

.statistics {
  margin-top: 3rem;
  display: block;

  .statisticsButton {
    margin-left: .5rem;
  }

  .statisticsLabel {
    margin-left: .5rem;
  }

  .statisticsNumber {
    font-weight: bold;
    color: $color-primary;
  }

  .statisticsRow {
    align-items: center;
    display: flex;
  }

  .statisticsTable {
    margin-top: 1rem;
  }
}

.surveyHeading {
  margin-top: 2.5rem;
}

.questionResults {
  &:first-child {
    margin-top: .75rem;
  }

  &:not(:first-child) {
    margin-top: 2.5rem;
  }
}
