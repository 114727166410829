@import "../../../style/variable";
@import "../../../style/tool";
@import "../../../style/media-breakpoints";

$field-padding: 1rem 0 .5rem;
$transition-speed: .2s;

.bullet,
.checkmark {
  border: 2px solid $greish-brown;
  height: 1.35rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.35rem;
}

.bullet {
  border-radius: 50%;

  &::after {
    background: $greish-brown;
    border-radius: 50%;
    content: "";
    display: block;
    height: .6rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
    width: .6rem;
  }
}

.checkmark {
  &::after {
    border-bottom: .19rem solid $greish-brown;
    border-right: .19rem solid $greish-brown;
    content: "";
    display: block;
    height: .75rem;
    left: .35rem;
    opacity: 0;
    position: absolute;
    top: .1rem;
    transform: scale(2) rotate(-45deg);
    width: .44rem;
  }
}

.container {
  display: block;
  position: relative;
  height: 3.8rem;
  margin: .5rem 0;
  width: 100%;

  &.fixedWidth {
    &,
    .input {
      @include for-phone-large-up {
        width: convert-unit(rem, 350px);
      }
    }
  }

  &.checkbox,
  &.radio {
    cursor: pointer;
    height: 2rem;

    &.disabled{
      cursor: initial;
    }

    .input {
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
      z-index: -1;
    }

    .label {
      color: $greish-brown;
      font-size: 1em;
      left: 2rem;
      top: .05rem;
    }
  }
}

.label {
  position: absolute;
  color: $silver-chalice;
  padding: $field-padding;
  transition: all $transition-speed ease-in-out;

  &.labelLowercase {
    text-transform: lowercase;
  }

  &.labelUp {
    padding: 0;
    font-size: .8em;
    transition: all $transition-speed ease-in-out;
  }

  &.labelError {
    color: $color-warning;
  }

  @at-root .input:focus ~ & {
    color: $warm-purple
  }
}

.input {
  position: absolute;
  background: none;
  border: none;
  border-bottom: 1px solid $greish-brown;
  padding: $field-padding;
  width: 100%;
  z-index: 200;

  &:focus {
    border-bottom: .15rem solid $warm-purple;
    outline: none;

    ~ .bullet {
      border-color: $color-primary;

      &::after {
        background: $color-primary;
      }
    }

    ~ .checkmark {
      border-color: $color-primary;

      &::after {
        border-bottom-color: $color-primary;
        border-right-color: $color-primary;
      }
    }
  }

  &:checked {
    ~ .bullet {
      &::after {
        animation: show-bullet-fill $transition-speed;
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }

    ~ .checkmark {
      &::after {
        animation: show-checkmark-fill $transition-speed;
        opacity: 1;
        transform: rotate(45deg);
      }
    }
  }
}

.annotation {
  position: absolute;
  bottom: 0;
  font-size: convert-unit(rem, 10px);
}

.error {
  color: $color-warning;
}

@keyframes show-bullet-fill {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }

  50% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  75% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(.75);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes show-checkmark-fill {
  from {
    opacity: 0;
    transform: scale(2) rotate(-45deg);
  }

  to {
    opacity: 1;
    transform: rotate(45deg);
  }
}

.contactGroup {
  cursor: not-allowed;
  opacity: 0.7;
}