.container {
  position: relative;

  .returnButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}

.form {
  margin-top: 2rem;
}