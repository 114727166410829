@import 'tool';

@mixin for-phone-up {
    @media (min-width: convert-unit(em, 350px)) {
        @content;
    }
}

@mixin for-phone-large-up {
    @media (min-width: convert-unit(em, 450px)) {
        @content;
    }
}

@mixin for-tablet-up {
    @media (min-width: convert-unit(em, 600px)) {
        @content;
    }
}

@mixin for-laptop-up {
    @media (min-width: convert-unit(em, 900px)) {
        @content;
    }
}

@mixin for-laptop-large-up {
    @media (min-width: convert-unit(em, 1200px)) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: convert-unit(em, 1400px)) {
        @content;
    }
}

@mixin for-tv-up {
    @media (min-width: convert-unit(em, 1800px)) {
        @content;
    }
}

@mixin for-phone-down {
    @media (max-width: convert-unit(em, 449px)) {
        @content;
    }
}

@mixin for-phone-large-down {
    @media (max-width: convert-unit(em, 599px)) {
        @content;
    }
}

@mixin for-dashboard-down {
    @media (max-width: convert-unit(em, 715px)) {
        @content;
    }
}

@mixin for-tablet-down {
    @media (max-width: convert-unit(em, 899px)) {
        @content;
    }
}

@mixin for-laptop-down {
    @media (max-width: convert-unit(em, 1199px)) {
        @content;
    }
}

@mixin for-sidebar-down {
    @media (max-width: convert-unit(em, 1300px)) {
        @content;
    }
}

@mixin for-laptop-large-down {
    @media (max-width: convert-unit(em, 1399px)) {
        @content;
    }
}

@mixin for-desktop-down {
    @media (max-width: convert-unit(em, 1799px)) {
        @content;
    }
}
