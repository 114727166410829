.table {
    margin-top: 1.5rem;
}

.loadMore {
    background: #932280;
    color: #fff;
    transition: 0.3s ease-out;
    display: inline-flex;
    padding: 10px 15px;
    margin-top: 16px;
    &:hover {
        background: adjust-color(#932280, $alpha: -0.9);
        cursor: pointer;
    }
}

.noMoreItems {
    color: #932280;
    margin-top: 16px;
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
}
