@import 'variable';

@font-face {
  font-family: Geomanist;
  src: url("../static/fonts/geomanist-regular.woff2") format("woff2");
}

@font-face {
  font-family: Geomanist;
  font-weight: 500;
  src: url("../static/fonts/geomanist-medium.woff2") format("woff2");
}


a {
  text-decoration: none;
}

body {
  font-family: $font-main;
  color: $font-color;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  font-weight: bold;
}
