@import "../../../style/variable";

.paragraph {
  margin: 0;

  &.smallFontSize {
    font-size: .85em;
  }

  &.muted {
    color: $color-muted;
  }
}