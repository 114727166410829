@import '../../../style/tool.scss';
@import '../../../style/media-breakpoints';

.footer {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: convert-unit(rem, 30px);
    z-index: 1;

    @include for-dashboard-down {
        margin-top: 120px;
    }

    .annotation {
        font-size: convert-unit(em, 10px);
        margin: 0 1.5rem 0 0.5rem;
    }

    .logo {
        width: convert-unit(rem, 179px);
    }
}
