@import '../../../../../../style/media-breakpoints';

.container {
    display: flex;
    flex-flow: column-reverse;

    @include for-tablet-up {
        flex-flow: row;
        justify-content: space-between;
    }
}

.modal {
    position: relative;

    @include for-phone-large-down {
        margin-top: 3rem;
    }

    .button {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    .table {
        margin-top: 1.5rem;
    }
}

.navigation {
    @include for-tablet-up {
        margin-left: 3rem;
    }
}

.tabs {
    margin-top: 16px;

    :global {
        .react-tabs {
            &__tab {
                background: white;
                color: #932280;
                border: 1px solid #932280;
                border-radius: 0;
                font-size: 14px;
                transition: 0.3s ease-out;
                &:hover {
                    background: adjust-color(#932280, $alpha: -0.9);
                }
                &--selected {
                    background: #932280;
                    pointer-events: none;
                    color: white;
                }
            }
            &__tab-list {
                border: none;
            }
        }
    }
}

.loadMore {
    background: #932280;
    color: #fff;
    transition: 0.3s ease-out;
    display: inline-flex;
    padding: 10px 15px;
    margin-top: 16px;
    &:hover {
        background: adjust-color(#932280, $alpha: -0.9);
        cursor: pointer;
    }
}

.noMoreItems {
    color: #932280;
    margin-top: 16px;
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
}
