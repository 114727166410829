@import "../../../style/media-breakpoints";

.form {
  @include for-tablet-up {
    align-items: center;
    display: flex;
  }

  .emailField {
    @include for-tablet-up {
      margin-left: 1rem;
    }
  }

  .submitButton {
    @include for-tablet-up {
      margin-left: 1rem;
    }
  }
}