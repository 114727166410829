@import "style/media-breakpoints";

.field {
  margin-top: .2rem;

  @include for-desktop-up {
    margin-top: .5rem;
  }
}

.submit {
  margin-top: 2rem;
}