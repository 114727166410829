.controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.section {
  margin-top: 3rem;
}

