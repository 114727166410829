@import "style/variable";

.icon {
  font-size: 0;
  display: inline-block;

  &.medium {
    height: 1.3rem;
    width: 1.3rem;
  }

  &.dark {
    fill: $warm-purple;
  }

  &.light {
    fill: $white;
  }

  &.ghost {
    fill: $silver-chalice;
  }

  &.small {
    height: .9rem;
    width: .9rem;
  }
}
