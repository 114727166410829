@import '../../../../../style/variable';
@import '../../../../../style/tool';

.answers {
    margin-top: 1rem;
    position: relative;

    .error {
        display: none;
        color: $color-warning;
        position: absolute;
        bottom: convert-unit(rem, -10px);
        font-size: convert-unit(rem, 10px);

        &:last-child {
            display: block;
        }
    }
}

.question {
    margin-top: 1.5rem;
}
