.modal {
  position: relative;

  .button {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .heading {
    margin-bottom: 1rem;
  }
}

.table {
  width: 100%;
}