@import 'variable';

@mixin skim-effect($pseudoselector) {
    overflow: hidden;
    position: relative;
    transition: all 0.3s;
    z-index: 0;

    &:#{$pseudoselector} {
        &::after {
            transition: all 0.3s;
            transform: translateX(100%);
        }
    }

    &::after {
        background: rgba($black, 0.2);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        width: 100%;
        z-index: -1;
    }
}

@mixin block-table($th-width) {
    display: block;
    position: relative;

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
        display: block;
    }

    td {
        overflow: hidden;
        padding-left: $th-width;
        position: relative;
        text-overflow: ellipsis;

        &::before {
            content: attr(data-column);
            left: 0;
            overflow: hidden;
            padding-right: 1rem;
            position: absolute;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: $th-width;
        }
    }

    thead {
        left: -9999px;
        position: absolute;
        top: -9999px;
    }
}
