@import '../../../style/variable';
@import '../../../style/tool';
@import '../../../style/media-breakpoints';

$field-padding: 1rem 0 0.5rem;
$transition-speed: 0.2s;

.annotation {
    position: absolute;
    bottom: 0;
    font-size: convert-unit(rem, 10px);
}

.container {
    height: 8rem;
    position: relative;
    width: 21.875rem;
}

.error {
    color: $color-warning;
}

.label {
    color: $silver-chalice;
    padding: $field-padding;
    position: absolute;
    text-transform: lowercase;
    transition: all $transition-speed ease-in-out;

    &.labelUp {
        padding: 0;
        font-size: 0.8em;
        transition: all $transition-speed ease-in-out;
    }

    @at-root .textarea:focus ~ & {
        color: $warm-purple;
    }
}

.textarea {
    background: none;
    border: none;
    border-bottom: 1px solid rgba($black, 0.6);
    height: 6.8rem;
    padding: $field-padding;
    position: absolute;
    resize: none;
    width: 100%;

    &:focus {
        border-bottom: 0.15rem solid $warm-purple;
        outline: none;
    }

    &.fixedWidth {
        @include for-phone-large-up {
            width: convert-unit(rem, 350px);
        }
    }
}
