@import '../../../style/variable';
@import 'style/media-breakpoints';

.hamburger {
    width: 35px;
    height: 30px;
    position: absolute;
    left: 2rem;
    cursor: pointer;
    display: inline-block;
    display: none;

    @include for-sidebar-down {
        display: block;
    }

    span {
        background-color: $warm-purple;
        position: absolute;
        transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
        width: 100%;
        height: 0.2rem;
        transition-duration: 500ms;

        &:nth-child(1) {
            top: 0px;
            left: 0px;
        }

        &:nth-child(2) {
            top: 13px;
            left: 0px;
            opacity: 1;
        }

        &:nth-child(3) {
            bottom: 0px;
            left: 0px;
        }
    }

    &__open {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
                top: 13px;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                top: 13px;
            }
        }
    }
}
