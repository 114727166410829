@import "../../style/variable";
@import "../../style/tool";
@import "../../style/media-breakpoints";

.container {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;

  @include for-tablet-up {
    align-items: center;
  }

  .button {
    margin-top: .35rem;
  }
}

.fileInput {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 200;

  &.fileInputDisabled {
    cursor: auto;
  }
}

.image {
  width: 100%;
  height: auto;
}

.wrapper {
  align-items: center;
  color: $greish-brown;
  display: flex;
  font-size: .8rem;
  min-height: convert-unit(rem, 70px);
  justify-content: center;
  padding: .2rem;
  text-align: center;
  width: convert-unit(rem, 127px);

  &.wrapperWithNoImage {
    background: $silver;
  }
}
