@import "style/tool";
@import "style/media-breakpoints";

.pageWrapper {
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: flex-start;
  position: relative;

  .contentContainer {
    align-items: center;
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: convert-unit(rem, 150px);
    margin-bottom: 5rem;

    @include for-desktop-up {
      margin-top: convert-unit(rem, 200px);
    }
  }
}
