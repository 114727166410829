@import '../../../style/variable';
@import '../../../style/tool';
@import '../../../style/media-breakpoints';

$field-padding: 1rem 0 0.5rem;
$transition-speed: 0.2s;

.container {
    width: 45%;
}

.select {
    margin-top: 25px;
    width: 100%;
    border: 1px solid #888;
    padding: 5px 10px;
}

.label {
    position: absolute;
    color: $silver-chalice;
    padding: $field-padding;
    transition: all $transition-speed ease-in-out;

    &.labelLowercase {
        text-transform: lowercase;
    }

    &.labelUp {
        padding: 0;
        // font-size: 0.8em;
        transition: all $transition-speed ease-in-out;
    }

    &.labelError {
        color: $color-warning;
    }

    @at-root .input:focus ~ & {
        color: $warm-purple;
    }
}
