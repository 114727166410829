@import '../../style/tool';
@import '../../style/media-breakpoints';
@import '../../style/variable';

.container {
    height: 100%;
    overflow: hidden;
    padding: 0.5rem;

    &.containerWithScroll {
        overflow: auto;
    }
}

.modal {
    background: $white;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-flow: column;
    padding: convert-unit(rem, 30px);
    position: relative;

    &.modalWithScroll {
        overflow: hidden;
        max-height: 90%;
    }

    &.full {
        width: 100%;
    }

    &.compact {
        @include for-phone-large-up {
            width: 30rem;
        }
    }

    .closeIcon {
        position: absolute;
        right: 2rem;
        top: 2rem;
        // z-index: 999999;
        z-index: 1;
    }
}

.wrapper {
    background: rgba($black, 0.15);
    position: fixed;
    z-index: 800;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.concreteBackground {
        background: $greish-brown;
    }
}
