@import "../../../style/media-breakpoints";

.controls {
  margin-top: 2rem;

  @include for-tablet-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.field {
  margin-top: .2rem;

  @include for-desktop-up {
    margin-top: .5rem;
  }
}

.submitButton {
  @include for-phone-large-down {
    margin-bottom: 2rem;
  }
}