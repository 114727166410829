@import "../../../style/variable";
@import "../../../style/tool";
@import "../../../style/mixins";

.button {
    display: inline-flex;
    cursor: pointer;
    border: none;
    padding: 0;
    z-index: 0;
    position: relative;
    
    &:disabled {
        cursor: default;
        background: $color-muted !important;
    }
    
    &:focus {
        box-shadow: 0 0 0 .3rem rgba($color-primary, .3) !important;
        transition: box-shadow .2s;
        border: none;
        outline: none;
    }
    
    &.small {
        align-items: center;
        justify-content: center;
        height: convert-unit(rem, 29px);
        
        &.fixedWidth {
            width: 10rem;
        }
        
        &:not(.fixedWidth) {
            padding-left: .3rem;
            padding-right: .3rem;
        }
    }
    
    &.big {
        height: convert-unit(rem, 48px);
        
        &.fixedWidth {
            width: convert-unit(rem, 173px);
        }
    }
    
    &.primary {
        background: $color-primary;
        color: white !important;
    }
    
    &.warning {
        background: $color-warning;
        color: white !important;
    }
    
    &.transitionEffects {
        &:not(:disabled) {
            @include skim-effect(hover);
            
            &:active {
                &::after {
                    transform: translateX(-100%);
                    transition: all .1s;
                }
            }
        }
    }
    
    &.secondary {
        background: $white;
        color: $color-primary;
    }
    
    &.shadow {
        box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
    }
    
    &.withIcon {
    }
    
    &.fixedWidth {
        align-items: center;
        display: flex;
        
        &.big {
            padding-left: 2.5rem;
            text-align: left;
            
            .icon {
                position: absolute;
                left: .7rem;
            }
        }
    }
    
    &.transparent {
        background: transparent;
    }
    
    &:not(.fixedWidth) {
        .icon {
            margin-right: .3rem;
        }
    }
    
    .icon {
        @at-root .button.fixedWidth & {
            left: 1rem;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }
    }
}
