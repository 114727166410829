@import "../../../style/variable";

.anchor {
  color: $color-primary !important;
  cursor: pointer;
  position: relative;

  &.disabled {
    color: $silver-chalice !important;
  }

  &.transitions {
    &::after {
      content: "";
      background: $color-primary;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: -20%;
      opacity: 0;
      transition: all .2s;
      transform: translateY(150%);
    }

    &:hover {
      &::after {
        opacity: .8;
        transition: all .2s;
        transform: none;
      }
    }
  }

  &.smallFontSize {
    font-size: .85em;
  }
}