@import '~normalize.css';
// CORE
@import 'reset';
@import 'typography';
@import 'variable';
@import 'elements';
// LAYOUT

#root {
    height: 100%;
}

div[class^='modal_container'] {
    z-index: 0;
}

[class^='modal_closeIcon'] {
    z-index: 9999999 !important;
}
