@import "../../style/variable";
@import "../../style/tool";

$loader-background: rgba($black, .15);
$loader-circle-color: $color-primary;

.container {
  background: $loader-background;
  display: block;
  height: 100%;
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;

  .circle {
    animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: convert-unit(rem, 4px) solid $loader-circle-color;
    border-radius: 50%;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    z-index: 1001;
    transform: translate(-50%, -50%);

    &.second {
      animation-delay: -0.5s;
    }
  }
}

@keyframes ripple {
  0% {
    height: 0;
    opacity: 1;
    width: 0;
  }

  100% {
    height: convert-unit(rem, 72px);
    opacity: 0;
    width: convert-unit(rem, 72px);
  }
}