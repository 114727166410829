@import "../../../../../../style/media-breakpoints";

.container {
  display: flex;
  flex-flow: column-reverse;

  @include for-tablet-up {
    flex-flow: row;
    justify-content: space-between;
  }
}

.description {
  margin-top: 1.5rem;
}

.modal {
  position: relative;

  @include for-phone-large-down {
    margin-top: 3rem;
  }

  @include for-tablet-up {
    margin-right: 3rem;
  }

  .button {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .table {
    margin-top: 1.5rem;
    width: 100%;
  }
}

.navigation {
  @include for-tablet-up {
    margin-left: 2rem;
  }
}