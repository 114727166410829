@import "../../style/tool";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navigation {
  &.navigationSelected {
    margin-top: convert-unit(rem, 11px);
  }
}

.wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;

  .heading {
    margin-bottom: convert-unit(rem, 38px);
  }
}
