@import '../../style/tool';
@import 'style/media-breakpoints';

.header {
    background: #fff;
    height: convert-unit(rem, 90px);
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    &__container {
        width: 100%;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include for-laptop-up {
            width: convert-unit(rem, 940px);
        }
    }

    .logo {
        width: convert-unit(rem, 40px);
    }
}
