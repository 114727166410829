@import "../../../style/media-breakpoints";

.controls {
  display: flex;
  margin-top: 2rem;

  @include for-phone-large-down {
    align-items: flex-end;
    flex-flow: column;
  }

  @include for-tablet-up {
    justify-content: flex-end;
  }

  .controlsButton {
    &:not(:first-child) {
      @include for-tablet-up {
        margin-left: 1rem;
      }
    }
  }
}
