@import "../../../style/variable";
@import "../../../style/tool";

.heading {
  letter-spacing: convert-unit(em, 0.09px);
  margin: 0;

  &.h1 {
    font-size: convert-unit(em, 30px);
    text-transform: uppercase;
  }

  &.h2 {
    font-size: convert-unit(em, 24px);
  }

  &.h1,
  &.h2 {
    color: $warm-purple;
    font-weight: 400;

    &:after {
      background: $warm-purple;
      content: "";
      display: block;
      height: .2rem;
      margin-top: 1rem;
      width: convert-unit(em, 25px);
    }
  }

  &.h3 {
    font-size: convert-unit(em, 24px);
    font-weight: 500;
  }

  &.h4 {
    color: $silver-chalice;
    font-size: convert-unit(em, 18px);
    font-weight: 500;
  }

  &.h5 {
    color: $warm-purple;
    font-size: convert-unit(em, 16px);
    font-weight: 400;
  }
}