.actionButton {
    &:not(:first-child) {
        margin-left: 0.5rem;
    }
}

.dataCell {
    min-height: 2rem;

    &.dataCellWithElement {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.languages {
    display: flex;
}

.language {
    width: 20px;
    height: 20px;
    border: 1px solid #aaa;
    border-radius: 10px;
    cursor: pointer;
    opacity: 0.9;
    margin-right: 8px;
    &:hover {
        opacity: 1;
    }
}

.languageTranslated {
    filter: grayscale(0);
    opacity: 0.9;
}

.languageNotTranslated {
    filter: grayscale(1);
    opacity: 0.4;
}

.languagePartiallyTranslated {
    filter: grayscale(0);
    opacity: 0.5;
}

.unsubscribed {
    opacity: 0.6;
    cursor: pointer;
    position: relative;
    &:before {
        // content: 'Úr áskrift';
        position: absolute;
        text-transform: uppercase;
        font-size: 8px;
        color: #f00;
        margin-top: 3px;
        font-weight: 500;
    }
    &:hover {
        opacity: 1;
    }
}

.modal {
    // position: absolute;
    // z-index: 999999999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 40px 40px;
    width: calc(916px - 80px);
    max-width: calc(100% - 80px);
    max-height: calc(100% - 80px);
}

.closeButton {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    svg {
        cursor: pointer;
        height: 30px;
        width: 30px;
        fill: #932280;
    }
}

.modalContainer {
    width: 100%;
}
