$theme-color: #2286dd;
$dark: #1a1a1a;

$black: #000;
$greish-brown: #535353;
$wild-sand: #f4f4f4;
$warm-purple: #932280;
$silver: #c7c7c7;
$silver-chalice: #9f9f9f;
$very-light-pink: #c1c1c1;
$torch-red: #fa1d3f;
$white: #ffffff;

$color-primary: $warm-purple;
$color-muted: $silver-chalice;
$color-warning: $torch-red;

$font-color: $dark;

$font-main: Geomanist, sans-serif;
$font-heading: Geomanist, sans-serif;