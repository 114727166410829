.controls {
  display: flex;
  margin-top: 1rem;

  .controlsButton {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.message {
  margin-top: 1rem;
}