@import "../../../style/variable";

.container {
  display: flex;
  align-items: flex-end;

  .button {
    position: relative;
    z-index: 500 !important;

    &::after {
      content: '';
      width: 100%;
      height: 50%;
      background: $white;
      position: absolute;
      z-index: 1000;
      top: 100%;
      left: 0;
    }

    &.activeButton {
      height: 2.5rem;
    }
  }
}