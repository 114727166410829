@import 'style/media-breakpoints';
@import 'style/tool';

.content {
    .container {
        display: flex;
        flex-flow: column-reverse nowrap;

        @include for-laptop-up {
            flex-flow: nowrap;
        }

        .buttons {
            display: flex;
            margin-bottom: convert-unit(rem, 50px);
            z-index: 0;

            @include for-phone-large-down {
                flex-flow: column;
            }

            @include for-laptop-up {
                flex-flow: column;
                align-items: flex-end;
                margin-left: convert-unit(rem, 50px);
                width: 20%;
            }

            .button {
                &:not(:first-child) {
                    @include for-phone-large-down {
                        margin-top: 1rem;
                    }

                    @include for-tablet-up {
                        margin-left: 1rem;
                    }

                    @include for-laptop-up {
                        margin-left: 0;
                        margin-top: 1rem;
                    }
                }
            }
        }

        .tiles {
            display: flex;
            flex-flow: wrap;
            margin: -#{convert-unit(rem, 25px)};

            @include for-laptop-up {
                width: 80%;
            }

            .tile {
                height: convert-unit(rem, 150px);
                margin: convert-unit(rem, 25px);
                position: relative;
                padding: convert-unit(rem, 30px);
                width: 100%;
                cursor: pointer;
                transition: 0.3s ease-out;
                &:hover {
                    box-shadow: 0 0 1.8rem rgba(0, 0, 0, 0.3);
                }

                @include for-tablet-up {
                    width: convert-unit(rem, 300px);
                }

                .tileParagraph {
                    margin-top: convert-unit(rem, 14px);
                }

                .tileButton {
                    position: absolute;
                    top: convert-unit(rem, 35px);
                    right: convert-unit(rem, 33px);
                }
            }
        }
    }
}
