@import '../../../style/media-breakpoints';
@import '../../../style/variable';
@import '../../../style/tool';

.form {
    position: relative;
}

.title {
    font-size: 18px;
    font-weight: 500;
    color: #bebebe;
    margin-bottom: 40px;
}

.titleLanguage {
    color: #9c01a1;
}

.controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;

    .formControlsButton {
        &:not(:first-child) {
            margin-left: convert-unit(rem, 30px);
        }
    }
}

.logoInput {
    @include for-tablet-up {
        position: absolute !important;
        right: convert-unit(rem, 30px);
        top: convert-unit(rem, 30px);
    }
}

.sections {
    .sectionsControls {
        border-top: 1px solid $color-primary;
        margin-top: 4rem;
        padding-top: 0.5rem;

        .sectionAddButton {
            width: 15rem;
        }
    }

    .section {
        background: $wild-sand;
        padding: 1rem 2rem;
        margin-top: 5rem;

        .sectionInner {
            margin-right: 2rem;
            width: convert-unit(rem, 350px);
            position: relative;

            .sectionRemoveButton {
                position: absolute;
                top: 1rem;
                right: 0;
                z-index: 300;
            }
        }
    }
}

.questions {
    .questionContainer {
        margin-top: 3rem;
    }

    .questionControls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem 0 0;
        border-top: 1px solid rgba($silver-chalice, 0.6);
        margin-top: 1rem;

        .questionControlsLabel {
            color: $greish-brown;
            display: flex;
            position: absolute;
            left: 2rem;

            .questionControlsLabelQuestionPreview {
                max-width: 8rem;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                margin-left: 0.2rem;
                text-overflow: ellipsis;
            }

            .questionControlsLabelAnswersCount {
                margin-left: 1rem;
                color: $silver-chalice;
            }

            &.questionControlsLabelHidden {
                opacity: 0;
            }

            &.questionControlsLabelWarning {
                color: $color-warning;

                .questionControlsLabelAnswersCount {
                    color: $color-warning;
                }
            }
        }
    }

    .question {
        &.questionHidden {
            display: none;
        }

        display: flex;
        flex-flow: column;

        input {
            padding-right: 2rem;
        }

        @include for-tablet-up {
            flex-flow: row;
        }

        .inner {
            display: flex;
            flex-flow: column;
            width: 100%;

            .questionAddButton {
                position: absolute;
                right: 0;
                top: 4rem;
                z-index: 300;
            }

            .inner2 {
                margin-right: 2rem;
                position: relative;

                .questionRemoveButton {
                    position: absolute;
                    top: 0.9rem;
                    right: 0;
                    z-index: 300;
                }
            }
        }

        .answers {
            margin-right: 2rem;
            display: flex;
            flex-flow: column;
            width: 100%;

            @include for-tablet-up {
                width: 50%;
            }

            .answerAddButton {
                align-self: flex-end;
                margin-right: 17px;
            }

            .answer {
                position: relative;
                width: 100%;

                input {
                    padding-right: 2rem;
                }

                .answerRemoveButton {
                    position: absolute;
                    right: 0;
                    top: 0.9rem;
                    z-index: 300;
                }
            }
        }
    }
}

.row {
    display: flex;
    .icon {
        padding-top: 15px;
        position: relative;
        z-index: 9999999;
    }
}
