@import "../../../../style/tool";
@import "../../../../style/media-breakpoints";

.form {
  margin-top: 2rem;
}

.imageUpload {
  @include for-tablet-down {
    margin-top: 2rem;
    width: 9rem;
  }

  @include for-laptop-up {
    position: absolute;
    right: 6em;
    top: 6rem;
  }
}

.message {
  margin-top: 2rem;
}
