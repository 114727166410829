@import "../../style/tool";
@import "../../style/media-breakpoints";

.container {
  width: 100%;
  padding: 0 2rem;
  position: relative;
  margin-bottom: 4rem;

  @include for-laptop-up {
    width: convert-unit(rem, 900px);
  }

  .logo {
    right: 3rem;
    position: absolute;
    top: 3.5rem;
  }
}

.dialog {
  padding: 3.5rem 3rem;

  .dialogDescription {
    margin-top: 2rem;
  }
}

.form {
  margin-top: 4rem;
}

.surveyDescription {
  margin-top: 2rem;
}

.surveyHeading {
  margin-top: 3rem;
}

.formatHint {
  margin-top: 1rem;
  text-align: right;
  width: 30%;
  margin-left: auto;

  @include for-phone-large-down {
    width: 100%;
  }
}