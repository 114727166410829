@import "../../../../../../style/media-breakpoints";

.contactGroupHeading {
  margin-top: 2rem;
}

.warning{
  margin-top: 1rem;
  margin-bottom: 0;
  color: #9F6000;
  font-size: .85em;
}

.controls {
   margin-top: 3rem;
  display: flex;

  @include for-phone-large-down {
    flex-flow: column;
    align-items: flex-end;
  }

  @include for-tablet-up {
    justify-content: flex-end;
  }

  .control {
    &:not(:first-child) {
      @include for-phone-large-down {
        margin-top: 1rem;
      }

      @include for-tablet-up {
        margin-left: 1rem;
      }
    }
  }
}

.description {
  margin-top: 1.5rem;
}

.forms {
  margin-top: 1rem;

  .table {
    @include for-phone-large-down {
      margin-top: 1rem;
    }

    width: 100%;
  }
}

.formatHint {
  margin-top: 1rem;
  text-align: right;
  width: 50%;
  margin-left: auto;

  @include for-phone-large-down {
    width: 100%;
  }
}

.modal {
  position: relative;

  .returnButton {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}
