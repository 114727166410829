@import "../../../style/media-breakpoints";
@import "../../../style/variable";
@import "../../../style/mixins";

$heading-color: $silver-chalice;

.table {
  font-size: .9rem;

  @include for-phone-large-down {
    @include block-table(30%);

    td {
      padding-bottom: .25rem;
      padding-top: .25rem;

      &::before {
        color: $heading-color;
      }
    }

    tr {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }

  &.compact {
    td {
      @include for-tablet-up {
        padding: .3rem 0 0;
      }
    }
  }

  &.spacious {
    td {
      @include for-tablet-up {
        padding: 0.8rem 0 0;
      }
    }
  }

  td {
    vertical-align: middle;
  }

  td,
  th {
    &:not(:first-child) {
      @include for-tablet-up {
        padding-left: 2rem;
      }
    }
  }

  th {
    color: $heading-color;
    font-weight: normal;
    padding-bottom: 1rem;
    text-transform: lowercase;
  }
}