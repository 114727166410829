@import "../../../../../../style/tool";
@import "../../../../../../style/media-breakpoints";

.description {
  margin-top: 1.5rem;
  max-width: convert-unit(rem, 300px);

  @include for-laptop-up {
    max-width: convert-unit(rem, 412px);
  }
}

.form {
  margin-top: 1.5rem;
}

.logo {
  @include for-phone-large-down {
    margin-top: .5rem;
  }

  @include for-tablet-up {
    position: absolute;
    right: 5rem;
    top: 3rem;
  }
}

.modal {
  .closeButton {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}