@import '../../style/tool';
@import '../../style/variable';
@import "style/media-breakpoints";

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    min-width: convert-unit(rem, 200px);
    background-color: $warm-purple;
    z-index: 1;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;

    @include for-sidebar-down {
        left: convert-unit(rem, -300px);

        &__visible{
            left: 0;
        }
    }

    &__container {
        padding: convert-unit(rem, 35px);
        padding-top: 0;
        margin-top: convert-unit(rem, 150px);

        @include for-desktop-up {
            margin-top: convert-unit(rem, 200px);
          }
    }

    &__menu {
        position: relative;
    }

    &__accordion {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        &__content {
            margin-top: 10px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: -5px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: white;
            }

            a {
                color: white;
                display: block;
                width: auto;
                flex-basis: 100%;
                margin-bottom: 5px;
                -webkit-transition: all 0.3s;
                -o-transition: all 0.3s;
                transition: all 0.3s;

                &:hover {
                    opacity: 0.8;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__icons {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: convert-unit(rem, 35px);
        width: 100%;
        display: flex;

        a {
            &:first-child {
                padding-right: convert-unit(rem, 30px);
            }
        }

        svg {
            fill: white !important;
        }
    }
}

.voice {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    text-decoration: none;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        outline: none;
    }
}
